import { Controller } from '@hotwired/stimulus'
import 'datatables.net-bs4'

import { camelCase, language } from 'scripts/utils/document'
import { serialize } from 'scripts/utils/form'
import { getControllerForElement } from 'scripts/utils/stimulus'
import { FR } from 'scripts/admin/locales/datatable/fr'

export default class extends Controller {
  static targets = ['columnCheckbox', 'filterBox', 'filterForm', 'rowCheckbox', 'table']
  static values = { options: Object, sessionKey: String, url: String }

  /* ----- LIFECYCLE CALLBACKS ----- */

  initialize() {
    this.selectedRowIds = []
    this.allInputSelected = false

    this.selectors = {
      tableCallout: '.js-datatable-callout'
    }
  }

  connect() {
    this.setupFilterForm()
    this.setupTable()
  }

  /* ----- ACTION EVENTS ----- */

  onColumnCheckboxClick(event) {
    this.toggleAllRowCheckboxes()
  }

  onRowCheckboxClick(event) {
    this.toggleRowCheckbox(event.currentTarget)
  }

  onSelectAllListCheckboxesClick(event) {
    event.preventDefault()
    this.allInputSelected = true
    this.refreshAllListCheckboxes()
  }

  onUnselectAllListCheckboxesClick(event) {
    event.preventDefault()
    this.allInputSelected = false
    this.refreshAllListCheckboxes()
  }

  onFilterFormSubmit(event) {
    event.preventDefault()
    const params = serialize(event.currentTarget)
    sessionStorage.setItem(this.sessionKey, params)
    this.updateTableSource(params)
  }

  onFilterFormResetClick(event) {
    const selectElements = this.filterFormTarget.querySelectorAll('select:not(.select2)')
    Array.from(selectElements).forEach(element => $(element).find('option').removeAttr('selected'))

    const select2Elements = this.filterFormTarget.querySelectorAll('select.select2')
    Array.from(select2Elements).forEach(element => $(element).val('').trigger('change'))
    sessionStorage.removeItem(this.sessionKey)
    this.updateTableSource()
  }

  /* ----- FUNCTIONS ----- */

  setupFilterForm() {
    if (this.hasFilterFormTarget && this.sessionData && !this.urlParameters) {
      this.sessionData.split('&').forEach(data => {
        const [key, value] = decodeURIComponent(data).split('=')
        const elements = this.filterFormTarget.querySelectorAll(`[name="${key}"]`)

        // Set field value
        if (elements.length > 0) {
          // If data name has multiple elements, get the none hidden one
          const element = elements.length > 1 ? Array.from(elements).find(el => el.type !== 'hidden') : elements[0]

          // Open filter box if more than one data has a value
          if (value && this.hasFilterBoxTarget) {
            $(this.filterBoxTarget).CardWidget('expand')
          }

          if (['select-one', 'select-multiple'].includes(element.type)) {
            $(element).val(value).trigger('change')
          } else if (['checkbox', 'radio'].includes(element.type)) {
            element.checked = true
          } else {
            element.value = value
          }
        }
      })
    }
  }

  setupTable() {
    if (!this.element.querySelector('.dataTables_wrapper')) {
      this.$datatable = $(this.tableTarget).DataTable(this.options)
    }
  }

  updateTableSource(params) {
    const url = params ? `${this.url}?${params}` : this.url

    if (this.hasColumnCheckboxTarget) {
      this.columnCheckboxTarget.checked = false
    }

    this.selectedRowIds = []
    this.refreshTableCalloutContent()
    this.$datatable.ajax.url(url).load()
  }

  refreshAllListCheckboxes() {
    if (this.hasColumnCheckboxTarget) {
      if (this.allInputSelected) {
        this.rowCheckboxTargets.forEach(rowCheckbox => {
          rowCheckbox.disabled = true
          rowCheckbox.checked = true
        })

        this.columnCheckboxTarget.disabled = true
        this.columnCheckboxTarget.checked = true
      } else {
        this.rowCheckboxTargets.forEach(rowCheckbox => {
          rowCheckbox.disabled = false
          rowCheckbox.checked = this.selectedRowIds.includes(parseInt(rowCheckbox.value))
        })

        this.columnCheckboxTarget.disabled = false
        this.columnCheckboxTarget.checked = this.rowCheckboxTargets.every(checkbox => checkbox.checked)
      }
    }

    this.refreshTableCalloutContent()
  }

  toggleAllRowCheckboxes() {
    this.rowCheckboxTargets.forEach(rowCheckbox => {
      rowCheckbox.checked = this.columnCheckboxTarget.checked

      if (rowCheckbox.checked) {
        this.addSelectedRowId(rowCheckbox.value)
      } else {
        this.removeSelectedRowId(rowCheckbox.value)
      }
    })
  }

  toggleRowCheckbox(rowCheckbox) {
    if (rowCheckbox.checked) {
      this.columnCheckboxTarget.checked = this.rowCheckboxTargets.every(checkbox => checkbox.checked)
      this.addSelectedRowId(rowCheckbox.value)
    } else {
      this.removeSelectedRowId(rowCheckbox.value)
    }
  }

  addSelectedRowId(id) {
    this.selectedRowIds.push(parseInt(id))
    this.selectedRowIds = [...new Set(this.selectedRowIds)] // Remove duplicates
    this.selectedRowIds.sort()
    this.refreshTableCalloutContent()
  }

  removeSelectedRowId(id) {
    this.selectedRowIds = this.selectedRowIds.filter(rowId => parseInt(rowId) !== parseInt(id))
    this.columnCheckboxTarget.checked = false // Uncheck column checkbox
    this.refreshTableCalloutContent()
  }

  refreshTableCalloutContent() {
    if (this.allInputSelected) {
      this.setTableCalloutContent(this.totalSelectionMessage)
    } else if (this.hasColumnCheckboxTarget && this.columnCheckboxTarget.checked && this.$datatable.page.info().pages > 1) {
      this.setTableCalloutContent(this.partialSelectionMessage)
    } else {
      this.setTableCalloutContent('')
    }
  }

  setTableCalloutContent(content) {
    this.element.querySelector(this.selectors.tableCallout).innerHTML = content
  }

  /* ----- GETTERS / SETTERS ----- */

  get options() {
    return {
      ...this.defaultOptions,
      ...this.optionsValue
    }
  }

  get defaultOptions() {
    return {
      language: this.locale,
      searching: false,
      processing: false,
      serverSide: true,
      ajax: this.urlWithParameters,
      pagingType: 'full_numbers',
      lengthMenu: [25, 50, 100],
      order: [],
      dom: this.tableDom,
      scrollX: true,
      responsive: true,
      columnDefs: [],
      footerCallback: (row, data, start, end, display) => {
        if (row) {
          Array.from(row.querySelectorAll('.text-center')).forEach(cell => cell.classList.remove('text-center'))
        }
      },
      fnDrawCallback: (oSettings) => {
        this.refreshAllListCheckboxes()
      }
    }
  }

  get url() {
    return this.urlValue.split('?')[0]
  }

  get urlParameters() {
    return this.urlValue.split('?')[1]
  }

  get urlWithParameters() {
    if(!this.urlParameters && this.sessionData) {
      return `${this.url}?${this.sessionData}`
    } else {
      return this.urlValue
    }
  }

  get locale() {
    return this.locales[language]
  }

  get locales() {
    return {
      fr: FR
    }
  }

  get partialSelectionMessage() {
    return this.locale.oBarPartialSelectMsg.replace('_TOTAL_', this.$datatable.page.info().recordsTotal)
  }

  get totalSelectionMessage() {
    return this.locale.oBarTotalSelectMsg.replace('_TOTAL_', this.$datatable.page.info().recordsTotal)
  }

  get tableDom() {
    return `
      <"row"<"col-12"<"callout callout-primary bg-primary dataTables_callout js-datatable-callout">>>
      <"row"<"col-lg-5 pb-2"i><"col-lg-7"p>>
      <"row"<"col-12"tr>>
      <"row"<"col-lg-5 pt-2"l><"col-lg-7"p>>
    `
  }

  get sessionData() {
    return sessionStorage.getItem(this.sessionKey)
  }

  get sessionKey() {
    if (this.hasFilterFormTarget && this.filterFormTarget.id) {
      return camelCase(this.filterFormTarget.id).concat('FormData')
    }

    return this.sessionKeyValue
  }
}
