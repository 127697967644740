import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  /* ----- LIFECYCLE CALLBACKS ----- */

  /* ----- ACTION EVENTS ----- */

  onBtnClick(event) {
    event.preventDefault()

    const searchForm = new FormData(this.element)
    const exportBtnHref = new URL(event.currentTarget.href)

    new URLSearchParams(searchForm).forEach((value, key) => {
      key = key.replace('q[', 'export[ransack_params[').replace(']', ']]')
      exportBtnHref.searchParams.set(key, value)
    })

    event.currentTarget.href = exportBtnHref
  }

  /* ----- FUNCTIONS ----- */

  /* ----- GETTERS / SETTERS ----- */
}