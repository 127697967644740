import { Controller } from '@hotwired/stimulus'
import { getController } from 'scripts/utils/stimulus'

export default class extends Controller {
  /* ----- LIFECYCLE CALLBACKS ----- */

  /* ----- ACTION EVENTS ----- */

  onAjaxSuccess(event) {
    const [response, status, xhr] = event.detail
    const responseOption = response.body.querySelector('option')
    const inputReferrer = document.querySelector(`select[data-creation-referrer="${event.params.referrer}"]`)

    responseOption.selected = true
    inputReferrer.append(responseOption)
    inputReferrer.dispatchEvent(new Event('change'))

    getController(this, 'modal').hide()
  }

  /* ----- FUNCTIONS ----- */

  /* ----- GETTERS / SETTERS ----- */
}
