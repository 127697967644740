import { Controller } from '@hotwired/stimulus'
import Sortable from 'sortablejs'

export default class extends Controller {
  static targets = ['container', 'draggable']

  /* ----- LIFECYCLE CALLBACKS ----- */

  connect() {
    this.setup()
  }

  /* ----- FUNCTIONS ----- */

  setup() {
    new Sortable(this.containerTarget, this.options)
  }

  /* ----- GETTERS / SETTERS ----- */

  get options() {
    return {
      draggable: '[data-sortable-target="draggable"]',
      handle: '.position-handle',
      onEnd: function (event) {
        let container = event.to
        const customEvent = new Event('draggable:onEnd')
        container.dispatchEvent(customEvent)
      }
    }
  }
}
