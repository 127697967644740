import { Controller } from '@hotwired/stimulus'
import 'select2'

export default class extends Controller {
  static targets = ['select']
  static values = { options: Object }

  /* ----- LIFECYCLE CALLBACKS ----- */

  connect() {
    this.setup()
  }

  /* ----- ACTION EVENTS ----- */

  onOpenFocusin(event) {
    this.open(event.currentTarget)
  }

  /* ----- FUNCTIONS ----- */

  setup() {
    const self = this

    if (!this.element.querySelector('.select2-container')) {
      $(this.selectTarget).select2(this.options)

      if (this.type === 'single') {
        $($(this.selectTarget).data('select2').$container)[0].dataset.action = 'focusin->inputs--select2#onOpenFocusin'
      }

      $(this.selectTarget).on('select2:open', function(e) {
        self.setAutofocus()
      })

      $(this.selectTarget).on('select2:select select2:unselect', function () {
        let event = new Event('change')
        this.dispatchEvent(event)
      })
    }
  }

  open(target) {
    $(target.parentNode.querySelectorAll('select:enabled')).select2('open')
  }

  setAutofocus() {
    let searchFields = document.querySelectorAll(".select2-container--open .select2-search__field")
    searchFields[searchFields.length - 1].focus()
  }

  /* ----- GETTERS / SETTERS ----- */

  get type() {
    return this.selectTarget.hasAttribute('multiple') ? 'multiple' : 'single'
  }

  get options() {
    let options = this.defaultOptions

    if (this.selectTarget.dataset.source) {
      options = { ...options, ...this.autocompleterOptions }
    }

    return {
      ...options,
      ...this.optionsValue
    }
  }

  get defaultOptions() {
    const placeholder = this.selectTarget.querySelector('option[value=""]') ? this.selectTarget.querySelector('option[value=""]').innerHTML : this.selectTarget.getAttribute('placeholder')
    const minimumResultsForSearch = this.selectTarget.length <= 8 ? Infinity : 1

    return {
      width: '100%',
      disabled: this.selectTarget.hasAttribute('readonly'),
      placeholder: placeholder,
      minimumResultsForSearch: minimumResultsForSearch,
      allowClear: true,
      multiple: this.type === 'multiple',
      templateSelection: function(obj) {
        if (obj.element) {
          const selectionLabel = obj.element.dataset.selectionLabel

          if (selectionLabel) {
            obj.selectionLabel = selectionLabel
          }
        }

        return obj.selectionLabel ? $(obj.selectionLabel) : obj.text
      },
      templateResult: function(obj, container) {
        if (obj.element) {
          const resultLabel = obj.element.dataset.resultLabel

          if (resultLabel) {
            obj.resultLabel = resultLabel
          }
        }

        return obj.resultLabel ? $(obj.resultLabel) : $('<span></span>').html(obj.text)
      },
    }
  }

  get autocompleterOptions() {
    return {
      minimumInputLength: 2,
      minimumResultsForSearch: 1,
      ajax: {
        url: this.selectTarget.dataset.source,
        quietMillis: 250,
        dataType: 'json',
        data: function (params) {
          return {
            search: params.term,
            page: params.page || 1
          }
        }
      }
    }
  }
}
