import { Controller } from '@hotwired/stimulus'
import Modal from 'bootstrap/js/src/modal'

export default class extends Controller {
  /* ----- LIFECYCLE CALLBACKS ----- */

  connect() {
    this.modal = new Modal(this.element)
    this.show()
  }

  disconnect() {
    const backdrop = document.querySelector('.modal-backdrop')

    if (backdrop) {
      backdrop.remove()
    }
  }

  /* ----- ACTION EVENTS ----- */

  /* ----- FUNCTIONS ----- */

  show() {
    this.modal.show()
  }

  hide() {
    this.modal.hide()
  }

  /* ----- GETTERS / SETTERS ----- */
}
