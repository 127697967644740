import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['input']

  /* ----- LIFECYCLE CALLBACKS ----- */

  connect() {
    let sessionStorageLocale = sessionStorage.getItem('my_account_admin_locale')

    if (!sessionStorageLocale) {
      sessionStorageLocale = document.documentElement.lang
    }

    this.localeChange(sessionStorageLocale)
  }

  /* ----- ACTION EVENTS ----- */

  localeChange(locale) {
    this.element.classList.toggle('hide', !this.hasInputTarget || !this.inputTarget.id.endsWith(`_${locale}_input`))
  }

  /* ----- FUNCTIONS ----- */

  /* ----- GETTERS / SETTERS ----- */
}
