import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['companyNameInput', 'sitesSelector']

  /* ----- LIFECYCLE CALLBACKS ----- */

  /* ----- ACTION EVENTS ----- */

  onTypeRadioChange(event) {
    this.toggleCompanyNameInput(event.currentTarget)
  }

  onRoleRadioChange(event) {
    this.toggleShowSitesSelector(event.currentTarget)
  }

  /* ----- FUNCTIONS ----- */

  toggleCompanyNameInput(element) {
    if (element.value == "individual") {
      this.companyNameInputTarget.classList.add('d-none')
    } else {
      this.companyNameInputTarget.classList.remove('d-none')
    }
  }

  toggleShowSitesSelector(element) {
    if (["webmaster", "commercial"].includes(element.value)) {
      this.sitesSelectorTarget.classList.remove('d-none')
    } else {
      this.sitesSelectorTarget.classList.add('d-none')
    }
  }

  /* ----- GETTERS / SETTERS ----- */
}
