import { Controller } from '@hotwired/stimulus'
import { getControllers } from 'scripts/utils/stimulus'

export default class extends Controller {
  /* ----- LIFECYCLE CALLBACKS ----- */

  connect() {
    let sessionStorageLocale = sessionStorage.getItem('my_account_admin_locale')

    if (!sessionStorageLocale) {
      sessionStorageLocale = document.documentElement.lang
    }

    $(this.element).val(sessionStorageLocale).trigger('change')
  }

  /* ----- ACTION EVENTS ----- */

  onChange(event) {
    this.setLocale(this.element.value)
  }

  /* ----- FUNCTIONS ----- */

  setLocale(locale) {
    this.element.value = locale
    sessionStorage.setItem('my_account_admin_locale', locale)

    getControllers(this, 'translated-content').forEach(controller => {
      controller.localeChange(locale)
    })

    getControllers(this, 'inputs--translated-field').forEach(controller => {
      controller.localeChange(locale)
    })
  }

  /* ----- GETTERS / SETTERS ----- */
}
