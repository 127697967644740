import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['categorisationFields', 'presetsSelector']

  /* ----- LIFECYCLE CALLBACKS ----- */

  /* ----- ACTION EVENTS ----- */

  onCategorisationTypeRadioChange(event) {
    if (event.currentTarget.value == "presets") {
      this.categorisationFieldsTarget.classList.add('d-none')
      this.presetsSelectorTarget.classList.remove('d-none')
    } else {
      this.categorisationFieldsTarget.classList.remove('d-none')
      this.presetsSelectorTarget.classList.add('d-none')
    }
  }

  /* ----- GETTERS / SETTERS ----- */
}
