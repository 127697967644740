import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = { locale: String }

  /* ----- LIFECYCLE CALLBACKS ----- */

  connect() {
    let sessionStorageLocale = sessionStorage.getItem('my_account_admin_locale')

    if (!sessionStorageLocale) {
      sessionStorageLocale = document.documentElement.lang
    }

    this.localeChange(sessionStorageLocale)
  }

  /* ----- ACTION EVENTS ----- */

  localeChange(locale) {
    this.element.classList.toggle('hide', this.localeValue !== locale)
  }

  /* ----- FUNCTIONS ----- */

  /* ----- GETTERS / SETTERS ----- */
}
