import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['picture']

  /* ----- LIFECYCLE CALLBACKS ----- */

  onAddAssociation() {
    this.updatePicturesPosition()
  }

  onRemoveAssociation() {
    this.updatePicturesPosition()
  }

  onDraggableEnd() {
    this.updatePicturesPosition()
  }

  /* ----- FUNCTIONS ----- */

  updatePicturesPosition() {
    this.pictureTargets.filter(element => {
      return element.querySelector('[data-pictures-target="pictureDestruction"]').value != '1'
    }).forEach((element, index) => {
      element.querySelector('[data-pictures-target="picturePosition"]').value = index + 1
    })
  }

  /* ----- GETTERS / SETTERS ----- */
}